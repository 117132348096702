
import {computed, defineComponent, ref, watch} from "vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import ImageUpload from "@/components/base/upload/ImageUpload.vue";
import {useGroupStore} from "@/store/group.store";
import GroupService from "@/core/services/GroupService";
import {Field} from "vee-validate";

export default defineComponent({
  name: "Setting",
  components: {ImageUpload, BaseForm, FormErrorMessage, Field},
  setup() {
    const submitting = ref(false);
    const model = ref({name: '', logo: ''});
    const teamStore = useGroupStore();
    const entity = computed<any>(() => teamStore.entity);
    model.value = {name: entity.value?.name, logo: entity.value?.logo}
    watch(entity, cb => {
      model.value = {name: cb.name, logo: cb.logo}
    })
    return {
      submitting,
      entity,
      model,
      teamStore,
    }
  },
  methods: {
    save() {
      this.submitting = true;
      GroupService.update(this.entity.id, this.model).then(res => {
        this.teamStore.setEntity(res);
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
